var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("工作台首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("订货管理")]),
              _c("el-breadcrumb-item", [_vm._v("寄售订货订单")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-plus",
                    size: "mini"
                  },
                  on: {
                    click: function($event) {
                      return _vm.dataCreate()
                    }
                  }
                },
                [_vm._v("新增寄售订货订单")]
              )
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "search-form-card", attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  model: _vm.searchForm,
                  inline: true,
                  "label-suffix": ":",
                  size: "small"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchFormSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "经销商" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { clearable: "", filterable: "" },
                        model: {
                          value: _vm.searchForm.distributorId,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "distributorId", $$v)
                          },
                          expression: "searchForm.distributorId"
                        }
                      },
                      _vm._l(_vm.manufacturerList, function(item, index) {
                        return _c("el-option", {
                          key: "distributorId" + index,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "订单状态" } },
                  [
                    _c(
                      "el-select",
                      {
                        model: {
                          value: _vm.searchForm.state,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "state", $$v)
                          },
                          expression: "searchForm.state"
                        }
                      },
                      _vm._l(_vm.stateList, function(item, index) {
                        return _c("el-option", {
                          key: "state" + index,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "发货状态" } },
                  [
                    _c(
                      "el-select",
                      {
                        model: {
                          value: _vm.searchForm.deliveryState,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "deliveryState", $$v)
                          },
                          expression: "searchForm.deliveryState"
                        }
                      },
                      _vm._l(_vm.deliveryStateList, function(item, index) {
                        return _c("el-option", {
                          key: "state" + index,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "订单编号" }
                  },
                  [
                    _c("el-input", {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.searchForm.orderNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "orderNumber", $$v)
                        },
                        expression: "searchForm.orderNumber"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "发票号" }
                  },
                  [
                    _c("el-input", {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.searchForm.invoiceNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "invoiceNumber", $$v)
                        },
                        expression: "searchForm.invoiceNumber"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "采购日期" }
                  },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        "unlink-panels": true,
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        clearable: ""
                      },
                      model: {
                        value: _vm.searchForm.dateRange,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "dateRange", $$v)
                        },
                        expression: "searchForm.dateRange"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "产品主码" }
                  },
                  [
                    _c("el-input", {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.searchForm.barCode,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "barCode", $$v)
                        },
                        expression: "searchForm.barCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "产品名称" }
                  },
                  [
                    _c("el-input", {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.searchForm.productName,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "productName", $$v)
                        },
                        expression: "searchForm.productName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "产品编码" }
                  },
                  [
                    _c("el-input", {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.searchForm.productNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "productNumber", $$v)
                        },
                        expression: "searchForm.productNumber"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "规格型号" }
                  },
                  [
                    _c("el-input", {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.searchForm.specificationModel,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "specificationModel", $$v)
                        },
                        expression: "searchForm.specificationModel"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "品牌" }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { clearable: "", filterable: "" },
                        model: {
                          value: _vm.searchForm.brandId,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "brandId", $$v)
                          },
                          expression: "searchForm.brandId"
                        }
                      },
                      _vm._l(_vm.brandList, function(item, index) {
                        return _c("el-option", {
                          key: "brandId" + index,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchFormExpand,
                        expression: "searchFormExpand"
                      }
                    ],
                    attrs: { label: "备注" }
                  },
                  [
                    _c("el-input", {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.searchForm.remarks,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "remarks", $$v)
                        },
                        expression: "searchForm.remarks"
                      }
                    })
                  ],
                  1
                ),
                _c("el-form-item", [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: {
                            click: function($event) {
                              return _vm.searchFormSubmit()
                            }
                          }
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { margin: "0 10px" },
                          attrs: { icon: "el-icon-download" },
                          on: {
                            click: function($event) {
                              return _vm.dataExport()
                            }
                          }
                        },
                        [_vm._v("导出")]
                      ),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary", underline: false },
                          on: {
                            click: function($event) {
                              _vm.searchFormExpand = !_vm.searchFormExpand
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.searchFormExpand ? "合并" : "展开")
                          ),
                          _c("i", {
                            class:
                              "el-icon-arrow-" +
                              (_vm.searchFormExpand ? "up" : "down") +
                              " el-icon--right"
                          })
                        ]
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              "margin-bottom": "10px",
              "font-size": "14px",
              display: "flex",
              "justify-content": "space-between",
              background: "#f5f7fa",
              "align-items": "center",
              height: "40px"
            }
          },
          [
            _c("div", [
              _c(
                "div",
                { staticClass: "tab-list" },
                _vm._l(_vm.tabList, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: "tab" + index,
                      class:
                        _vm.activeTabId === item.ID
                          ? "tab-item hover"
                          : "tab-item",
                      on: {
                        click: function($event) {
                          return _vm.tabChange(item)
                        }
                      }
                    },
                    [
                      item.ID === 4
                        ? _c(
                            "el-badge",
                            {
                              attrs: {
                                value: item.number,
                                hidden: item.number > 0 ? false : true
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(item.name))]),
                              item.number === 0
                                ? _c("span", [
                                    _vm._v("（"),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v("0")
                                    ]),
                                    _vm._v("）")
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _c("span", [
                            _c(
                              "span",
                              { class: item.ID === 5 ? "text-danger" : "" },
                              [_vm._v(_vm._s(item.name))]
                            ),
                            _c("span", [
                              _vm._v("（"),
                              _c("span", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(item.number))
                              ]),
                              _vm._v("）")
                            ])
                          ])
                    ],
                    1
                  )
                }),
                0
              )
            ]),
            _c("div", {
              staticStyle: {
                display: "flex",
                "justify-content": "flex-end",
                "align-items": "center",
                "font-size": "12px"
              }
            })
          ]
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dataLoading,
              expression: "dataLoading"
            }
          ],
          ref: "dataTableRef",
          attrs: {
            hasIndex: true,
            pagination: _vm.searchForm,
            columns: _vm.columns,
            data: _vm.dataList,
            cacheName: _vm.dataTableCacheName
          },
          on: {
            "row-click": _vm.dataRowClick,
            "row-dblclick": _vm.dataRowDbClick
          },
          scopedSlots: _vm._u([
            {
              key: "createdByName",
              fn: function(ref) {
                var row = ref.row
                return [
                  row.source === 2
                    ? _c("span", [_vm._v(_vm._s(row.createdByName))])
                    : _c("span", [
                        _vm._v("[后台 " + _vm._s(row.createdByName) + "]")
                      ])
                ]
              }
            },
            {
              key: "state",
              fn: function(ref) {
                var row = ref.row
                return [
                  row.state === 9
                    ? _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "驳回原因：" + row.saleCauseOfRejection,
                            placement: "top"
                          }
                        },
                        [
                          _c(
                            "el-link",
                            { attrs: { type: "danger", underline: false } },
                            [_vm._v("驳回")]
                          )
                        ],
                        1
                      )
                    : row.state === 3 || row.state === 4
                    ? _c(
                        "el-popover",
                        {
                          attrs: {
                            trigger: "click",
                            placement: "bottom",
                            width: "530"
                          },
                          on: {
                            show: function($event) {
                              return _vm.deliveryShow(row)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "el-table",
                                {
                                  key: "deliveryTable" + _vm.timestamp,
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "mini",
                                    border: "",
                                    data: row.stockout,
                                    "row-key": "deliveryOrderNo",
                                    "expand-row-keys": _vm.expandRowKeys
                                  },
                                  on: { "row-click": _vm.deliveryRowClick }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { type: "expand" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.selfDelivery === 1
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c("div", [
                                                        _vm._v("自运")
                                                      ]),
                                                      _c(
                                                        "el-row",
                                                        [
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 12
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "运输人员：" +
                                                                  _vm._s(
                                                                    scope.row
                                                                      .transportPersonnel
                                                                  )
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 12
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "运输人员电话：" +
                                                                  _vm._s(
                                                                    scope.row
                                                                      .transportationPhone
                                                                  )
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 12
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "运输环境温度：" +
                                                                  _vm._s(
                                                                    scope.row
                                                                      .environmentalTemperature
                                                                  )
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 12
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "车牌号：" +
                                                                  _vm._s(
                                                                    scope.row
                                                                      .plateNumber
                                                                  )
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 24
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "备注：" +
                                                                  _vm._s(
                                                                    scope.row
                                                                      .sendRemarks
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              scope.row.selfDelivery === 2
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c("div", [
                                                        _vm._v("快递")
                                                      ]),
                                                      _c(
                                                        "el-row",
                                                        [
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 12
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "快递单号：" +
                                                                  _vm._s(
                                                                    scope.row
                                                                      .courierNumber
                                                                  )
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 12
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "快递公司：" +
                                                                  _vm._s(
                                                                    scope.row
                                                                      .expressCompany
                                                                  )
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 12
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "快递公司电话：" +
                                                                  _vm._s(
                                                                    scope.row
                                                                      .expressCompanyPhone
                                                                  )
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 12
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "备注：" +
                                                                  _vm._s(
                                                                    scope.row
                                                                      .sendRemarks
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "出库单号",
                                      prop: "deliveryOrderNo",
                                      align: "center",
                                      "min-width": "200"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "数量",
                                      prop: "deliveryQuantity",
                                      align: "center",
                                      "min-width": "100"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "出库时间",
                                      prop: "deliveryTime",
                                      align: "center",
                                      "min-width": "150"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: {
                                slot: "reference",
                                type: row.state === 3 ? "warning" : "success"
                              },
                              nativeOn: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              },
                              slot: "reference"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  row.state === 3 ? "部分发货" : "全部发货"
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.stateFormatter(row.state))
                        }
                      })
                ]
              }
            },
            {
              key: "operations",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "div",
                    { staticClass: "control-column" },
                    [
                      (row.deliveryState === 3 || row.deliveryState === 4) &&
                      _vm.activeTabId !== 6
                        ? _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.toReceive(row)
                                }
                              }
                            },
                            [_vm._v("去收货")]
                          )
                        : _vm._e(),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.dataEdit(row)
                            }
                          }
                        },
                        [_vm._v("订单详情")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        }),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "total, sizes, prev, pager, next, jumper",
                align: "center",
                total: _vm.searchForm.total,
                "page-sizes": [5, 10, 15, 20, 30, 40, 50, 100],
                "current-page": _vm.searchForm.page,
                "page-size": _vm.searchForm.pageSize
              },
              on: {
                "current-change": _vm.pageChange,
                "size-change": _vm.sizeChange
              }
            })
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              margin: "10px 0",
              "font-size": "12px",
              "font-weight": "bold"
            }
          },
          [_vm._v("订单明细")]
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailLoading,
              expression: "detailLoading"
            }
          ],
          ref: "detailTableRef",
          attrs: {
            hasIndex: true,
            pagination: _vm.detailForm,
            columns: _vm.detailColumns,
            data: _vm.detailList.slice(
              (_vm.detailForm.page - 1) * _vm.detailForm.pageSize,
              _vm.detailForm.page * _vm.detailForm.pageSize
            ),
            cacheName: _vm.detailTableCacheName
          },
          on: { "row-click": _vm.detailRowClick }
        }),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "total, sizes, prev, pager, next, jumper",
                align: "center",
                total: _vm.detailForm.total,
                "page-sizes": [5, 10, 15, 20, 30, 40, 50, 100],
                "current-page": _vm.detailForm.page,
                "page-size": _vm.detailForm.pageSize
              },
              on: {
                "current-change": _vm.detailPageChange,
                "size-change": _vm.detailSizeChange
              }
            })
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }