import { request } from '@/api/_service.js';
export function listDealerUserManufacturer(params) {
  return request({
    url: '/erp/get_dealer_user_manufacturer_list',
    method: 'get',
    params: params
  });
}
export function findDealerUserManu(params) {
  return request({
    url: '/dealerUserErp/findDealerUserManu',
    method: 'get',
    params: params
  });
}