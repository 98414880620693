import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { sendCode, updatePasswordMobile } from '@/api/global.js';
export default {
  components: {},
  data: function data() {
    var _this = this;

    var checkPhone = function checkPhone(rule, value, callback) {
      if (!value) {
        return callback(new Error('手机号不能为空'));
      } else {
        var reg = /^1[1|0|1|2|3|4|5|6|7|8|9][0-9]\d{8}$/;

        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    };

    var passwordValidator = function passwordValidator(rule, value, callback) {
      var reg = new RegExp('^(?=.*[0-9])(?=.*[a-zA-Z])(.{6,32})$');

      if (!reg.test(value)) {
        return callback(new Error('密码必须同时包含字母和数字'));
      }

      if (value.length < 8 || value.length > 20) {
        return callback(new Error('密码长度限8-20位'));
      }

      if (_this.form.newPassword !== _this.form.newPassword2) {
        return callback(new Error('确认新密码与新密码必须一致'));
      } else {
        callback();
      }
    };

    return {
      dialogTitle: '',
      dialogVisible: false,
      loading: false,
      sendCodeCD: 0,
      sendCodeLoading: false,
      form: {
        mobile: '',
        code: '',
        newPassword: ''
      },
      formRules: {
        newPassword: [{
          required: true,
          message: '请输入新密码',
          trigger: 'blur'
        }, {
          validator: passwordValidator
        }],
        newPassword2: [{
          required: true,
          message: '请输入确认新密码',
          trigger: 'blur'
        }, {
          validator: passwordValidator
        }],
        code: [{
          required: true,
          message: '请输入短信码',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '请输入手机号码(账号)',
          trigger: 'blur'
        }, {
          validator: checkPhone,
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init(row) {
      var that = this;
      that.dialogVisible = true;
      that.dialogTitle = '忘记密码';
      that.form = {
        mobile: '',
        code: '',
        newPassword: ''
      };
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    formSubmit: function formSubmit() {
      var _this2 = this;

      var that = this;
      var form = JSON.parse(JSON.stringify(that.form));
      this.$refs.form.validate(function (valid, err) {
        if (valid) {
          that.loading = true;
          updatePasswordMobile(form).then(function (res) {
            that.loading = false;
            that.$notify.success('密码修改成功');
            that.passwordDialogClose();
          }, function (err) {
            console.log(err);
            that.$notify.error('密码修改失败');
            that.loading = false;
          }).finally(function () {
            that.loading = false;
          });
        } else {
          for (var item in err) {
            _this2.$message.error(err[item][0].message);

            return;
          }

          that.loading = true;
        }
      });
    },
    sendSmsCode: function sendSmsCode() {
      var that = this;

      if (that.sendCodeCD > 0) {
        return;
      }

      var reg = /^1[1|0|1|2|3|4|5|6|7|8|9][0-9]\d{8}$/;

      if (reg.test(that.form.mobile)) {
        that.sendCodeLoading = true;
        var form = {
          mobile: that.form.mobile,
          event: 'change_pass'
        };
        sendCode(form).then(function (res) {
          that.sendCodeCD = 60;
          that.sendSmsCodeCD();
          that.$message.success('发送成功，请注意查收短信码');
        }).finally(function () {
          that.sendCodeLoading = false;
        });
      } else {
        that.$message.error('请输入正确的手机号');
      }
    },
    sendSmsCodeCD: function sendSmsCodeCD() {
      var that = this;

      if (that.sendCodeCD > 0) {
        that.sendCodeCD = that.sendCodeCD - 1;
        setTimeout(function () {
          that.sendSmsCodeCD();
        }, 1000);
      } else {
        that.sendCodeCD = 0;
      }
    },
    passwordDialogClose: function passwordDialogClose() {
      var that = this;
      that.dialogVisible = false;
    }
  }
};