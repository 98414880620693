import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { downloadExcelTemplate } from '@/api/report.js';
import util from '@/libs/util.js';
import axios from 'axios';
import format from '@/libs/util.format';
import dayjs from 'dayjs';
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: 'Excel导入',
      dialogVisible: false,
      uploadFileType: ['.xls', '.xlsx'],
      uploadURL: '',
      uploadToken: '',
      loading: false,
      errorMsg: null,
      successMsg: null
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init(_ref) {
      var manufacturerId = _ref.manufacturerId;
      var that = this;
      that.dialogVisible = true;
      that.errorMsg = null;
      that.successMsg = null;
      that.uploadURL = process.env.VUE_APP_API + '/erpConsignReturnInfoErpNew/uploadExcel?manufacturerId4=' + manufacturerId;
      that.uploadToken = {
        Authorization: util.cookies.get('token'),
        Os: 'web',
        PLATFORM: 'Web'
      };
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    myUpload: function myUpload(content) {
      var _this = this;

      var that = this;
      that.errorMsg = null;
      that.successMsg = null;
      var form = new FormData();
      form.append('file', content.file);
      var config = {
        headers: content.headers,
        timeout: 1000 * 180
      };

      if (content.file.size / 1024 / 1024 > 30) {
        that.errorMsg = '不允许上传大于30MB的文件';
        that.$message.error(that.errorMsg);
        return false;
      }

      var FileExt = content.file.name.replace(/.+\./, '');

      if (that.uploadFileType.indexOf('.' + FileExt.toLowerCase()) === -1) {
        that.errorMsg = '不允许上传此类型文件';
        that.$message.error(that.errorMsg);
        return false;
      }

      this.loading = true;
      axios.post(content.action, form, config).then(function (res) {
        if (res.data.code !== 0) {
          that.errorMsg = res.data.msg;
          that.$message.error(that.errorMsg);
        } else {
          // that.$notify.success(res.data.msg)
          that.successMsg = res.data.msg;
          var listData = that.detailListFormat(res.data.data.list);
          that.$emit('add', listData);
        }
      }).catch(function (error) {
        console.log(error);

        if (error.response) {
          that.errorMsg = '文件上传失败,' + error.response.data;
        } else if (error.request) {
          that.errorMsg = '文件上传失败，服务器端无响应';
        } else {
          that.errorMsg = '文件上传失败，请求封装失败';
        }

        that.$message.error(that.errorMsg);
      }).finally(function () {
        _this.loading = false;
      });
    },
    detailListFormat: function detailListFormat(rows) {
      var that = this;
      var resultRows = [];

      try {
        rows.forEach(function (row, index) {
          resultRows.push({
            id: null,
            mainId: row.main_id,
            specsId: row.specs_id,
            inventoryId: row.ID,
            rowIndex: index,
            productNumber: row.specs.productNumber,
            productName: row.main.productName,
            specificationModel: row.specs.specificationModel,
            batchNumber: row.batch_number,
            salesQuantity: row.quantityUsed,
            stockQuantity: row.stock_quantity,
            normalQuantity: row.normalQuantity,
            consumedQuantity: row.consumedQuantity,
            receiptQuantity: row.receiptQuantity,
            issueLocking: row.issueLocking,
            salesPendingApproval: row.salesPendingApproval,
            productPackageLocking: row.productPackageLocking,
            unit: row.specs.unit,
            packUnit: row.specs.packUnit,
            packNum: row.specs.packNum,
            unitPrice: row.specs.wholesalePrice,
            registrationNumber: row.registrationNumber,
            manufacturerDate: row.manufacturer_date,
            validityDate: row.validity_date,
            expirationDate: row.main.expirationDate,
            productPlace: row.main.productPlace,
            brandName: row.main.brandName,
            sterilizationBatchNumber: row.sterilization_batch_number,
            sterilizationDate: row.sterilization_date,
            wholesalePurchasePrice: row.specs.wholesalePurchasePrice,
            consignmentPurchasePrice: row.specs.consignmentPurchasePrice,
            marketPrice: row.specs.marketPrice,
            consignmentPrice: row.specs.consignmentPrice,
            wholesalePrice: row.specs.wholesalePrice,
            sterilizationWays: row.specs.sterilizationWays,
            trackingCode: row.trackingCode || null,
            afterTaxPrice: Number(Number(row.afterTaxPrice).toFixed(2)),
            genericName: row.specs.genericName,
            manufacturerName: row.manufacturerName,
            medCode: row.specs.medCode,
            manufacturer: row.main.manufacturer,
            returnQuantity: row.excelDeliveryQuantity,
            sumPrice: Number(Number(row.returnQuantity * row.afterTaxPrice).toFixed(2)),
            barCode: row.specs.barCode
          });
        });
      } catch (e) {
        console.log(e.message);
      }

      return resultRows;
    },
    templateDownload: function templateDownload() {
      downloadExcelTemplate().then(function (res) {
        var blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        }); // type这里表示xlsx类型

        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob); // 创建下载的链接

        downloadElement.href = href;
        downloadElement.download = 'EXCEL导入模板.xlsx'; // 下载后文件名

        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载

        document.body.removeChild(downloadElement); // 下载完成移除元素

        window.URL.revokeObjectURL(href); // 释放掉blob对象
      });
    },
    // 是否过期
    isExpire: function isExpire(sdate) {
      if (sdate) {
        if (format.isDate(sdate)) {
          if (dayjs().isBefore(dayjs(sdate))) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    expireDays: function expireDays(sdate) {
      return sdate ? Math.ceil(dayjs(sdate).diff(dayjs(), 'days', true)) : 9999;
    }
  }
};